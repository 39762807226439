import { ChevronUp, ChevronDown } from 'lucide-react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDisclosure } from '@repo/ui/hooks/useDisclosure';
import { Button } from '@repo/ui/components/ui/button';
import { Icon } from '@repo/ui/components/Icon';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@repo/ui/components/ui/dropdown-menu';

export default function NavBar() {
  const isBrowser = typeof window !== 'undefined';
  const { user, logout } = useAuth0();
  const { isOpen, onClose, onToggle } = useDisclosure();

  return (
    <DropdownMenu open={isOpen} onOpenChange={onClose}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex items-center text-gray-700 dark:text-whiteAlpha-700 "
          onClick={onToggle}
        >
          {user?.name}{' '}
          <Icon as={isOpen ? ChevronUp : ChevronDown} className="w-4 h-4 text-gray-400 dark:text-whiteAlpha-500 ml-2" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem
          className="text-gray-700"
          onClick={() =>
            logout({
              logoutParams: {
                // TODO: unclear in which scenarios you want to retain the current URL
                returnTo: isBrowser ? window.location.origin : '',
              },
            })
          }
        >
          Logout
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
