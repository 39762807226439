'use client';

import type { PropsWithChildren } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import NavBar from '@/components/Nav/NavBar';
import useRemoveDarkModeFromLocalStorage from '@/hooks/useRemoveDarkMode';
import UserAnalyticsLayout from './UserAnalytics';
import GraphQLApolloProvider from '@/hooks/GraphQLApolloProvider';
import { UserPermissionsProvider } from '@/providers/UserPermissionsProvider';
import PostHogPageView from './PostHogPageView';
import RoutingListener from './RoutingListener';

if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || '', {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    capture_pageview: false, // captured manually
  });
}
function AuthenticatedLayout({ children }: PropsWithChildren<{}>) {
  const { user } = useAuth0();

  if (!user) {
    throw new Error('User not found');
  }
  // if we ever want to support dark mode again we should remove this
  useRemoveDarkModeFromLocalStorage();

  return (
    <UserPermissionsProvider>
      <PostHogProvider client={posthog}>
        <PostHogPageView />
        <GraphQLApolloProvider>
          <>
            <RoutingListener />
            <UserAnalyticsLayout />
            <div className="border-gray-200 dark:bg-grayDark-800 dark:border-grayDark-600 font-inter">
              <NavBar />
              <section className="flex h-[calc(100vh-64px)] overflow-hidden">{children}</section>
            </div>
          </>
        </GraphQLApolloProvider>
      </PostHogProvider>
    </UserPermissionsProvider>
  );
}

export default ({
  organization,
  invitation,
  children,
}: {
  organization: string | undefined;
  invitation: string | undefined;
  children: any;
}) => {
  if (invitation) {
    console.log(`Invitation being redeemed!: ${invitation}, Organization: ${organization}`);
  }

  return withAuthenticationRequired(AuthenticatedLayout, {
    loginOptions: {
      authorizationParams: {
        organization,
        invitation,
      },
    },
  })({ children });
};
