import { LuCalendarDays, LuMail, LuPhone } from 'react-icons/lu';
import ContactItem from './ContactItem';
import { Popover, PopoverContent, PopoverTrigger } from '@repo/ui/components/ui/popover';
import { Button } from '@repo/ui/components/ui/button';

const BASE_CALENDAR_URL = 'https://cal.com';
const CALENDAR_URL = `${BASE_CALENDAR_URL}/nick-gomez/chat`;

function ContactPopover() {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="gray-outline" className="text-gray-700">
          Contact us
        </Button>
      </PopoverTrigger>
      <PopoverContent className="px-2 py-3 w-64" onOpenAutoFocus={e => e.preventDefault()}>
        <div className="flex flex-col space-y-2">
          <ContactItem icon={LuCalendarDays} text="Schedule a meeting" url={CALENDAR_URL} />
          <ContactItem icon={LuMail} text="support@inkeep.com" url="mailto:support@inkeep.com?subject=Inkeep help" />
          <ContactItem icon={LuPhone} text="(239)-201-5206" url="tel:239-201-5206" />
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default ContactPopover;
