'use client';

import { type PropsWithChildren, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/nextjs';
import { usePostHog } from 'posthog-js/react';
import { GET_USER } from '@/schemes';
import { useQuery } from '@apollo/client';

export default function UserAnalyticsLayout({ children }: PropsWithChildren<{}>) {
  const { user } = useAuth0();

  if (!user) {
    throw new Error('User not found');
  }

  const { data } = useQuery(GET_USER, {
    variables: { userId: user.sub },
  });

  const posthog = usePostHog();

  Sentry.setUser({
    email: data?.user?.email && data?.user?.emailVerified ? data?.user?.email : undefined,
    username: data?.user?.name || undefined,
  });

  useEffect(() => {
    if (data) {
      posthog.identify(data?.user?.id || undefined, {
        email: data?.user?.email && data?.user?.emailVerified ? data?.user?.email : undefined,
        name: data?.user?.name || undefined,
      });
    }
  }, [data, posthog]);

  return null;
}
