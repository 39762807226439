'use client';

import { type PropsWithChildren, useEffect } from 'react';
import { type AppState, Auth0Provider } from '@auth0/auth0-react';
import posthog from 'posthog-js';

import { useRouter, useSearchParams } from 'next/navigation';
import AuthenticatedLayout from '@/components/Layout/AuthenticatedLayout';

if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || '', {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    capture_pageview: false, // captured manually
  });
}
export default function Inner({
  children,
  params,
}: PropsWithChildren<{ params: { organization: string; invitation: string } }>) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const isBrowser = typeof window !== 'undefined';
  const domain = process.env.AUTH0_DOMAIN || '';
  const clientId = process.env.AUTH0_CLIENT_ID || '';
  const audience = process.env.GRAPHQL_AUDIENCE_IDENTIFIER || '';
  const issuer = process.env.AUTH0_ISSUER || '';
  const redirect_uri = isBrowser ? window.location.origin : '/here';

  useEffect(() => {
    const error = searchParams.get('error');
    if (error && error === 'invalid_request') {
      router.push('/error');
    }
  }, [searchParams, router]);

  const onRedirectCallback = () => (appState: AppState | undefined) => {
    const searchParams = new URLSearchParams(window.location.search);
    const errorDescription = searchParams.get('error_description');
    const error = searchParams.get('error');
    if (error || errorDescription) {
      console.error('There was an Authentication error', error, errorDescription);
    }

    router.replace('/error');
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      issuer={issuer}
      authorizationParams={{
        redirect_uri,
        audience,
        scope:
          'openid profile email global_management_all_entities:all_tasks organization_management_all_entities:all_tasks organization_management_all_entities:read organization_management_analytics:read global_dashboard_analytics:write create:organization_invitations',
      }}
      // cacheLocation="localstorage"
      // useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback}
    >
      <AuthenticatedLayout organization={params.organization} invitation={params.invitation}>
        {children}
      </AuthenticatedLayout>
    </Auth0Provider>
  );
}
