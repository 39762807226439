import { gql } from '@apollo/client';

export const GET_ALL_ORG_META = gql`
  query allOrganizationsMetadata {
    allOrganizationsMetadata {
      id
      alias
      displayName
    }
  }
`;

export const GET_ORG_DEFAULTS = gql`
  query GetOrgDefaults {
    organization {
      id
      alias
      portalDisplayPreferences {
        id
        defaultProject {
          id
        }
      }
    }
    allProjects {
      id
    }
  }
`;

export const GET_USER_DEFAULT_PROJECT_ID = gql`
  query GetUserDefaultProjectId($userId: ID!) {
    user(userId: $userId) {
      id
      defaultProject {
        id
      }
    }
  }
`;

export const GET_DEFAULT_CUSTOM_QUESTION_ANSWERS_SOURCE_ID_ONLY = gql`
  query GetDefaultCustomQuestionAnswersSourceOnlyId($projectId: ID!) {
    project(projectId: $projectId) {
      id
      defaultCustomQuestionAnswersSource {
        id
      }
    }
  }
`;

export const GET_ORGANIZATION_AND_USER = gql`
  query GetOrganizationAndUser($userId: ID!, $projectId: ID!) {
    organization {
      id
      alias
      displayName
      portalDisplayPreferences {
        id
        defaultColorMode
        primaryBrandColor
        botAvatarSrcUrl
        botAvatarDarkSrcUrl
      }
    }
    project(projectId: $projectId) {
      displayName
      id
      chatSubjectName
      quickQuestions
      integrations {
        apiKey
        id
        type
        isDeactivated
      }
      getHelpCallToActions {
        id
        label
        url
        icon {
          ... on CustomIcon {
            id
            url
          }
          ... on BuiltInIcon {
            id
            name
          }
        }
        }
    }
    user(userId: $userId) {
      name
      email
      emailVerified
      id
    }
  }
`;

export const GET_USER = gql`
  query GetUser($userId: ID!) {
    user(userId: $userId) {
      name
      email
      emailVerified
      id
    }
  }
`;

export const GET_ORGANIZATION_ID = gql`
  query GetOrganizationId {
    organization {
      id
    }
  }
`;

export const GET_ORG_DATA = gql`
  query OrganizationAndProjectDataForIntegration($projectId: ID!) {
    organization {
      id
      portalDisplayPreferences {
        primaryBrandColor
        botAvatarSrcUrl
        botAvatarDarkSrcUrl
      }
    }
    project(projectId: $projectId) {
      chatSubjectName
      quickQuestions
      getHelpCallToActions {
        id
        label
        url
        icon {
          ... on CustomIcon {
            id
            url
          }
          ... on BuiltInIcon {
            id
            name
          }
        }
      }
    }
  }
`;
